import React, {useState, useEffect, useRef} from "react";
import {Form, Input, Button, Breadcrumb, Divider, Select, Space, Typography, Tag} from 'antd';
import {message} from "antd";
import { useParams, useNavigate} from "react-router";
import requester from "../../utils/requester";
import VJsonEditor from "../../components/jsonEditor/VJsonEditor";

const { Title } = Typography
// 

const AppConfig = () => {
    const [form] = Form.useForm();
    const [appConfig, setAppConfig] = useState({})
    const [remark, setRemark] = useState('')
    const [config, setConfig] = useState({
        json: {},
        text: undefined
    })
    // eslint-disable-next-line
    const {appid, appname, configid} = useParams()
    const [messageApi, contextHolder] = message.useMessage();

    const history = useNavigate()

    useEffect(() => {
        if (configid) {
            requester.get('/app/config/' + configid).then((response) => {  
                if (response.status === 200) {
                    var appConfig = response.data.data
                    setRemark(appConfig.app.remark)
                    appConfig.config = JSON.parse(appConfig.config)
                    // appConfig.config = JSON.stringify(JSON.parse(appConfig.config), null, 4) // 
                    form.setFieldsValue(appConfig)
                    setAppConfig(appConfig)
                    setConfig({json: appConfig.config, text: JSON.stringify(appConfig.config)})
                    console.log(appConfig)
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onFinish = (values) => {
        if (window.confirm("确定要提交保存吗？")) {
            try {
                values.config = JSON.parse(config.text)
                values.config = JSON.stringify(values.config)
                if (configid) {
                    putApp(values)
                } else {
                    postApp(values)
                }
            } catch (error) {
                console.log(error)
                alert("发生错误，请检查json是否存在格式错误");
            }
        }
    }

    const putApp = (values) => {
        requester.put("/app/config/" + configid, values).then((response) => {
            if (response.status === 200) {
                messageApi.success("保存成功")
                history.push(`/confighub/app/${appid}/configs`)
            }
        })
    }

     const postApp = (values) => {
        requester.post("/app/config/" + appid, values).then((response) => {
            if (response.status === 200) {
                messageApi.success("保存成功")
                history.push(`/confighub/app/${appid}/configs`)
            }
        })
     }

     const deleteConfig = () => {
        if (window.confirm("你在执行危险的删除操作，数据删除后无法恢复，你确认要删除此配置项吗？")) {
            requester.delete("/app/config/" + configid).then((response) => {
                if (response.status === 200) {
                    alert("删除成功")
                    history.push(`/confighub/app/${appid}/configs`)
                }
            })
        }
     }

    return (
        <div>
            {contextHolder}
            <Breadcrumb style={{ margin: '16px 0' }}
                items={[
                    {
                        title: "配置中心"
                    },
                    {
                        title: "App列表"
                    },
                    {
                        title: appname
                    },
                ]}
            />
            <Typography>
                <Title level={2}>你正在编辑{appname}的配置</Title>
            </Typography>
            <Form
                form={form}
                layout="vertical"
                initialValues={appConfig}
                // onValuesChange={onRequiredTypeChange}
                onFinish={onFinish}
                style={{ maxWidth: 1000 }}
            >
                <Form.Item label="版本" required tooltip="This is a required field" name="version">
                    <Input placeholder="例如1.0.0"  name="varsion"/>
                </Form.Item>
                <Form.Item label="状态" required tooltip="released 状态下只有超级管理员才能修改" name="status" valuePropName="value">
                    <Select 
                    options={[
                        {
                            value: 'developing',
                            label: '开发中'
                        },
                        {
                            value: 'released',
                            label: '已发布'
                        }
                    ]}/>
                </Form.Item>
                <Form.Item label="标记" tooltip="可添加多个标记，空格隔开，默认为空" name="tags">
                    <Input placeholder="例如强更"  name="tags"/>
                </Form.Item>
                <Form.Item label="设置项" tooltip="采用 json 格式，可以先到 bejson.com 编辑好json再复制过来提交即可">
                    <VJsonEditor content={config} onChange={setConfig}/>
                </Form.Item>
                <Form.Item>
                   <Space size={300}>
                       <Button type="primary" htmlType="submit">保存</Button>
                       {configid ?
                           <Button type="primary" danger onClick={deleteConfig}>删除此版本配置项</Button>
                           : <></>}
                   </Space>
                </Form.Item>
            </Form>
            <Divider orientation="left">配置说明</Divider>
            <pre>{remark}</pre>
        </div>
    )
}

export default AppConfig