import React from 'react'
import { Link} from 'react-router-dom'
import { Layout, Menu } from 'antd';
import {
    DesktopOutlined,
    PieChartOutlined,
} from '@ant-design/icons';
import './index.css'

const { Header, Content, Footer, Sider } = Layout;

const menuItems = [
    {key:"/feedback", title: "用户反馈", icon: <PieChartOutlined />},
    {key:"/confighub", title: "配置中心", icon: <DesktopOutlined />},
    {key:"/administrators", title: "管理员", icon: <DesktopOutlined />},
];

class Index extends React.Component {
    state = {
        collapsed: false,
    };

    onCollapse = collapsed => {
        this.setState({ collapsed });
    };

    render() {
        const { collapsed } = this.state;
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
                    <div className="logo">Super App</div>
                    <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                        {menuItems.map(item => (
                            <Menu.Item key={item.key} icon={item.icon}><Link to={item.key}>{item.title}</Link></Menu.Item>
                            ))}
                    </Menu>
                </Sider>
                <Layout className="site-layout">
                    <Header className="site-layout-background" style={{ padding: 0 }} />
                    <Content style={{ margin: '0 16px' }}>
                        {this.props.children}
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>Super App ©2023 Power by Ant Design</Footer>
                </Layout>
            </Layout>
        );
    }
}

export default Index;