import './App.css';
import {
  Route,
  Routes
} from 'react-router-dom'
import Index from './pages/index/Index';
import Login from './pages/login/Login';
import Administrators from './pages/administrator/Administrators';
import Feedback from './pages/feedback/Feedback';
import FeedbackDetail from './pages/feedback/FeedbackDetail';
import EditAdministrator from './pages/administrator/EditAdministrator';
import Version from './pages/version/Version';
import ViewVersion from './pages/version/ViewVersion';
import NewVersion from './pages/version/NewVersion';
import Apps from './pages/confighub/Apps';
import AppEdit from './pages/confighub/AppEdit'
import AppConfigs from './pages/confighub/AppConfigs';
import AppConfig from './pages/confighub/AppConfig';

function App() {
  return (
        <Index>
          <Routes>
            <Route path="/feedback" element={<Feedback/>}></Route>
            <Route path="/feedback/:id" element={<FeedbackDetail />}></Route>
            <Route path="/administrators" element={<Administrators />}></Route>
            <Route path="/administrator/:id?" element={<EditAdministrator />}></Route>
            <Route path='/version/create' element={<NewVersion/>}></Route>
            <Route path='/version' element={<Version/>}></Route>
            <Route path='/version/:id' element={<ViewVersion/>}></Route>
            <Route path='/login' element={<Login/>}></Route>
            <Route path='/confighub' element={<Apps/>}></Route>
            <Route path='/confighub/:appname/:id?' element={<AppEdit/>}></Route>
            <Route path='/confighub/:appname/:appid/configs' element={<AppConfigs/>}></Route>
            <Route path='/confighub/:appname/:appid/config/:configid?' element={<AppConfig/>}></Route>
          </Routes>
        </Index>
  );
}

export default App;
