import React from "react";
import { Form, Input, Button, Breadcrumb } from 'antd';
import requester from "../../utils/requester";
import { useNavigate } from "react-router-dom";

const NewVersion = () => {

    const [form] = Form.useForm();
    const history = useNavigate()

    const onRequiredTypeChange = () => {

    }

    const onFinish = (values) => {
        requester.post("/version", values).then((response) => {
            if (response.status === 200) {
                alert("添加成功！")
                history.push('/versions')
            }
        })
    }

    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>新增版本</Breadcrumb.Item>
            </Breadcrumb>

            <Form
                form={form}
                layout="vertical"
                onValuesChange={onRequiredTypeChange}
                onFinish={onFinish}
                autocomplete="off"
            >
                <Form.Item label="App Name" name="app_name" required tooltip="App名称">
                    <Input placeholder="App Name" />
                </Form.Item>
                <Form.Item label="Version" name="version" required tooltip="版本名称">
                    <Input placeholder="name" />
                </Form.Item>
                <Form.Item label="Version Code" name="version_code" tooltip="安卓的版本编号">
                    <Input placeholder="Version Code" value={''}/>
                </Form.Item>
                <Form.Item label="english" name="english" required tooltip="This is a required field">
                    <Input.TextArea placeholder="english" />
                </Form.Item>
                <Form.Item label="spanish" name="spanish" required tooltip="This is a required field">
                    <Input.TextArea placeholder="spanish" />
                </Form.Item>
                <Form.Item label="simplifiedChinese" name="simplifiedChinese" required tooltip="This is a required field">
                    <Input.TextArea placeholder="simplifiedChinese" />
                </Form.Item>
                <Form.Item label="traditionalChinese" name="traditionalChinese" required tooltip="This is a required field">
                    <Input.TextArea placeholder="traditionalChinese" />
                </Form.Item>
                <Form.Item label="platform" name="platform" required tooltip="输入ios或者adr">
                    <Input placeholder="platform" />
                </Form.Item>
                <Form.Item label="is_show" name="is_show" required tooltip="决定窗口是否显示">
                    <Input placeholder="is_show" />
                </Form.Item>
                <Form.Item label="Force Update" name="is_force" required tooltip="是否是强制更新版本">
                    <Input placeholder="is_force" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default NewVersion